import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"
import PageContentPath from "../components/PageContentPath"

import { isMobile } from "react-device-detect"
import { Power1, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap project_c_4_wrap">

        <div className="page_header">
          <h2 className="sub_title">駐村編輯</h2>
          <h1 className="main_title">萬華文史走讀</h1>
          <h6 className="speaker">策展人/林書豪 導覽人/陳德君</h6>
        </div>

        <div className="page_content project_c_4_1">

          <section className="row_area_s project_c_4_1_a">
            <div className="column_4">
              <div className="context">
                <p>邀請萬華社區小學的創辦人，走讀萬華地方文史，體驗城市博物館的概念，認識我們所不知道的萬華。</p>
                {/* <p className="tag"><i>#萬華老故事 #文化保存 #新舊發展</i></p> */}
                <p><strong>路線</strong> - <i>龍山寺站→銀河咖啡→福州元祖胡椒餅→三水食品花生行→大理福德宮→昔日木造老屋→立良二號 咖啡→艋舺服飾商圈→小時光豆奶→寺前公園→萬華林宅 星巴克→和平青草園(石敢當)→陳氏古厝→大理街160巷(二棵老樹)→糖廍文化史蹟公園</i></p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_b project_c_4_head row_break">
            <div className="column_5 onset_left_1 main_map">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_map.jpg")} alt="社區藝術走讀"/>
              </figure>
            </div>
            <div className="column_2 leader">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_lead.jpg")} alt="社區藝術走讀"/>
                <figcaption className="figcaption left">陳德君<br/>在地文化工作者</figcaption>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_c row_gallery">
            <div className="column_5 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_5.jpg")} alt="陳氏古厝"/>
              </figure>
              <div className="column_6">
                <div className="context">
                  <p>陳氏古厝一帶仍保有完整的傳統房子，走在巷弄間可以感受到濃濃的生活感，但因為是私人土地，只能在外觀看，值得一看的是匾額上頭的「星聚穎川」，穎川即是陳姓的堂號。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_d row_gallery">
            <div className="column_4 onset_left_2 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_4.jpg")} alt="石敢當"/>
              </figure>
              <div className="column_6">
                <div className="context">
                  <p>有鎮煞避邪作用的石敢當，與居民有著很深的連結，只要路過都會停下拜拜或是主動清理維護，同時也是附近居民泡茶聊天、聯絡感情的所在。茶具與香都直接放在兩旁的柱子裡，非常在地！</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_e row_gallery">
            <div className="column_5 row_area_s onset_left_3">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_2.jpg")} alt="大理福德宮"/>
              </figure>
              <div className="column_6 onset_left_2">
                <div className="context">
                  <p>位於巷弄的大理福德宮，祭拜著土地公，最大的特色是前後各有一棵老榕樹守護著。旁邊有間廟旁一定會有的麵攤，甚至有充滿人情味的「奉茶」能在店內看見，讓過路客能自行取用。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_f row_break">
            <div className="column_3">
              <div className="context">
                <h5>地方觀察探索工作坊</h5>
                <p>一路上走讀，設定「街區生活、青年創業、節慶信仰」3大主題，民眾手寫三色小卡，紀錄所觀察的。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_g">
            <div className="column_4 onset_left_4 offset_right_1 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_7.jpg")} alt="生活飲食故事工作坊"/>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_h row_gallery">
            <div className="column_6 onset_left_1 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_8.png")} alt="青年創業"/>
                <figcaption className="figcaption main center"><div className="text_inner">青年創業</div></figcaption>
              </figure>
              <div className="column_4 onset_left_1">
                <div className="context_s">
                  <p>以「小時光土豆奶」獲得最多人回響。創業總是從小小的攤車開始，卻有著大大的理想與精神。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_i row_gallery">
            <div className="column_6 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_9.png")} alt="街區生活"/>
                <figcaption className="figcaption main center"><div className="text_inner">街區生活</div></figcaption>
              </figure>
              <div className="column_4 onset_left_1">
                <div className="context_s">
                  <p>大理街的店家與住宅區的生活感各有人支持，另土地公廟旁麵店的奉茶也讓人感受到濃濃人情味。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_1_j">
            <div className="column_6 onset_left_2 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_1/project_c_4_1_10.png")} alt="節慶信仰"/>
                <figcaption className="figcaption main center"><div className="text_inner">節慶信仰</div></figcaption>
              </figure>
              <div className="column_5 onset_left_3">
                <div className="context_s right">
                  <p>信仰與生活完全融合在一起的「石敢當」，讓最多人印象深刻。</p>
                </div>
              </div>
            </div>
          </section>

          {!isMobile ? <PageContentPath /> : null}

        </div>


        <div className="page_footer">
          <Link to="/project_c_4_2" className="next_page">
            <div className="next_title">食物記憶走讀</div>
            <div className="next_owner">策展人/林書豪 導覽人/陳冠蓉</div>
          </Link>
        </div>

      </div>

    );
  }

}


export default Page
